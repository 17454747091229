// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeAvatar_ChangeAvatar__csLOs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ChangeAvatar_ChangeAvatar__csLOs .ChangeAvatar_avatarUpload__FzXJr {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ChangeAvatar_ChangeAvatar__csLOs .ChangeAvatar_avatarContainer__n\\+s1C {
  width: 100px;
  height: 100px;
  position: relative;
  cursor: pointer;
}
.ChangeAvatar_ChangeAvatar__csLOs .ChangeAvatar_avatarContainer__n\\+s1C .ChangeAvatar_editIcon__cfKW5 {
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/ChangeAvatar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAGI;EACE,kBAAA;EACA,cAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AADN","sourcesContent":[".ChangeAvatar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .avatarUpload {\n    width: 0.1px;\n    height: 0.1px;\n    opacity: 0;\n    overflow: hidden;\n    position: absolute;\n    z-index: -1;\n  }\n\n  .avatarContainer {\n    width: 100px;\n    height: 100px;\n    position: relative;\n    cursor: pointer;\n\n\n    .editIcon {\n      position: absolute;\n      display: block;\n      bottom: 0;\n      right: 0;\n      width: 40px;\n      height: 40px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChangeAvatar": `ChangeAvatar_ChangeAvatar__csLOs`,
	"avatarUpload": `ChangeAvatar_avatarUpload__FzXJr`,
	"avatarContainer": `ChangeAvatar_avatarContainer__n+s1C`,
	"editIcon": `ChangeAvatar_editIcon__cfKW5`
};
export default ___CSS_LOADER_EXPORT___;
