// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseCatalog_courseCatalog__u8Knp {
  margin-top: 10px;
}
.CourseCatalog_courseCatalog__u8Knp.CourseCatalog_mobile__sxYdo .CourseCatalog_titleRow__breZb .CourseCatalog_rightSection__ChFmU .CourseCatalog_resetButton__Bh7yi {
  display: flex;
}
.CourseCatalog_courseCatalog__u8Knp:hover .CourseCatalog_titleRow__breZb .CourseCatalog_rightSection__ChFmU .CourseCatalog_resetButton__Bh7yi {
  display: flex;
}
.CourseCatalog_courseCatalog__u8Knp.CourseCatalog_collapse__5FVRR .CourseCatalog_titleRow__breZb .CourseCatalog_collapseBtn__aLbUh {
  transform: rotate(-180deg);
}
.CourseCatalog_courseCatalog__u8Knp.CourseCatalog_collapse__5FVRR .CourseCatalog_sectionList__cXP8o {
  height: 0;
  overflow: hidden;
}
.CourseCatalog_courseCatalog__u8Knp .CourseCatalog_titleRow__breZb {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--color-8);
  height: 24px;
}
.CourseCatalog_courseCatalog__u8Knp .CourseCatalog_titleRow__breZb .CourseCatalog_collapseBtn__aLbUh {
  width: 16px;
  transition: transform 0.2s ease-in;
}
.CourseCatalog_courseCatalog__u8Knp .CourseCatalog_titleRow__breZb .CourseCatalog_leftSection__RSiZ5 {
  flex: 1 1;
}
.CourseCatalog_courseCatalog__u8Knp .CourseCatalog_titleRow__breZb .CourseCatalog_rightSection__ChFmU {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.CourseCatalog_courseCatalog__u8Knp .CourseCatalog_titleRow__breZb .CourseCatalog_rightSection__ChFmU .CourseCatalog_resetButton__Bh7yi {
  margin: 0 6px;
  display: none;
}
.CourseCatalog_courseCatalog__u8Knp .CourseCatalog_sectionList__cXP8o {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/CourseCatalog/CourseCatalog.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAIQ;EACE,aAAA;AAFV;AAWQ;EACE,aAAA;AATV;AAiBM;EACE,0BAAA;AAfR;AAmBI;EACE,SAAA;EACA,gBAAA;AAjBN;AAqBE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,qBAAA;EACA,YAAA;AAnBJ;AAqBI;EACE,WAAA;EACA,kCAAA;AAnBN;AAsBI;EACE,SAAA;AApBN;AAsBI;EACE,cAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;AApBN;AAsBM;EACE,aAAA;EACA,aAAA;AApBR;AAyBE;EACE,iBAAA;AAvBJ","sourcesContent":[".courseCatalog {\n  margin-top: 10px;\n\n  &.mobile {\n    .titleRow {\n      .rightSection {\n        .resetButton {\n          display: flex;\n        }\n      }\n    }\n  }\n\n  &:hover {\n    .titleRow {\n      .rightSection {\n        .resetButton {\n          display: flex;\n        }\n      }\n    }\n  }\n\n  &.collapse {\n    .titleRow {\n      .collapseBtn {\n        transform: rotate(-180deg);\n      }\n    }\n\n    .sectionList {\n      height: 0;\n      overflow: hidden;\n    }\n  }\n\n  .titleRow {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n    color: var(--color-8);\n    height: 24px;\n\n    .collapseBtn {\n      width: 16px;\n      transition: transform 0.2s ease-in;\n    }\n\n    .leftSection {\n      flex: 1 1 0;\n    }\n    .rightSection {\n      flex: 0 0 auto;\n      display: flex;\n      flex-flow: row nowrap;\n      align-items: center;\n\n      .resetButton {\n        margin: 0 6px;\n        display: none;\n      }\n    }\n  }\n\n  .sectionList {\n    padding-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseCatalog": `CourseCatalog_courseCatalog__u8Knp`,
	"mobile": `CourseCatalog_mobile__sxYdo`,
	"titleRow": `CourseCatalog_titleRow__breZb`,
	"rightSection": `CourseCatalog_rightSection__ChFmU`,
	"resetButton": `CourseCatalog_resetButton__Bh7yi`,
	"collapse": `CourseCatalog_collapse__5FVRR`,
	"collapseBtn": `CourseCatalog_collapseBtn__aLbUh`,
	"sectionList": `CourseCatalog_sectionList__cXP8o`,
	"leftSection": `CourseCatalog_leftSection__RSiZ5`
};
export default ___CSS_LOADER_EXPORT___;
