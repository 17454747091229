// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavDrawer_navDrawerWrapper__BjyTm {
  flex: 0 0 auto;
  background: var(--color-5);
}
.NavDrawer_navDrawerWrapper__BjyTm .NavDrawer_navDrawer__32rC\\+ {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.NavDrawer_navDrawerWrapper__BjyTm .NavDrawer_bodyWrapper__LeM3k {
  flex: 1 1;
  overflow-y: auto;
}
.NavDrawer_navDrawerWrapper__BjyTm .NavDrawer_bodyWrapper__LeM3k::-webkit-scrollbar {
  display: none;
}
.NavDrawer_navDrawerWrapper__BjyTm .NavDrawer_popUpWindowExpand__ny-dG {
  transform: translate(-50%);
  width: 250px;
  top: auto;
  bottom: 80px;
  left: 50%;
  z-index: 999;
}
.NavDrawer_navDrawerWrapper__BjyTm .NavDrawer_popUpWindowCollapse__5GL0n {
  width: 250px;
  top: auto;
  bottom: 10px;
  left: 70px;
  z-index: 999;
}
.NavDrawer_navDrawerWrapper__BjyTm.NavDrawer_mobile__w81lj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 900;
}
.NavDrawer_navDrawerWrapper__BjyTm.NavDrawer_mobile__w81lj .NavDrawer_navHeader__xBHuV {
  visibility: hidden;
}
.NavDrawer_navDrawerWrapper__BjyTm.NavDrawer_mobile__w81lj .NavDrawer_navDrawer__32rC\\+ {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/NavDrawer/NavDrawer.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,0BAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,wBAAA;EACA,kBAAA;AACJ;AAEE;EACE,SAAA;EACA,gBAAA;AAAJ;AAEI;EACE,aAAA;AAAN;AAIE;EACE,0BAAA;EACA,YAAA;EACA,SAAA;EACA,YAAA;EACA,SAAA;EACA,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;AAHJ;AAME;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AAJJ;AAMI;EACE,kBAAA;AAJN;AAOI;EACE,YAAA;EACA,aAAA;EACA,wBAAA;EACA,kBAAA;AALN","sourcesContent":[".navDrawerWrapper {\n  flex: 0 0 auto;\n  background: var(--color-5);\n\n  .navDrawer {\n    height: 100%;\n    display: flex;\n    flex-flow: column nowrap;\n    position: relative;\n  }\n\n  .bodyWrapper {\n    flex: 1 1 0;\n    overflow-y: auto;\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n\n  .popUpWindowExpand {\n    transform: translate(-50%);\n    width: 250px;\n    top: auto;\n    bottom: 80px;\n    left: 50%;\n    z-index: 999;\n  }\n\n  .popUpWindowCollapse {\n    width: 250px;\n    top: auto;\n    bottom: 10px;\n    left: 70px;\n    z-index: 999;\n  }\n\n  &.mobile {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: block;\n    z-index: 900;\n\n    .navHeader {\n      visibility: hidden;\n    }\n\n    .navDrawer {\n      height: 100%;\n      display: flex;\n      flex-flow: column nowrap;\n      position: relative;\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navDrawerWrapper": `NavDrawer_navDrawerWrapper__BjyTm`,
	"navDrawer": `NavDrawer_navDrawer__32rC+`,
	"bodyWrapper": `NavDrawer_bodyWrapper__LeM3k`,
	"popUpWindowExpand": `NavDrawer_popUpWindowExpand__ny-dG`,
	"popUpWindowCollapse": `NavDrawer_popUpWindowCollapse__5GL0n`,
	"mobile": `NavDrawer_mobile__w81lj`,
	"navHeader": `NavDrawer_navHeader__xBHuV`
};
export default ___CSS_LOADER_EXPORT___;
