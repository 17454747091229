// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingHeader_settingHeader__vArtz {
  height: 60px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  background: rgba(248, 251, 252, 0.8);
  backdrop-filter: blur(15px);
}

.SettingHeader_clickable__UbuiY {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/SettingHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,2BAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".settingHeader {\n  height: 60px;\n  padding: 0 30px;\n  display: flex;\n  align-items: center;\n  background: rgba(248, 251, 252, 0.8);\n  backdrop-filter: blur(15px);\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingHeader": `SettingHeader_settingHeader__vArtz`,
	"clickable": `SettingHeader_clickable__UbuiY`
};
export default ___CSS_LOADER_EXPORT___;
