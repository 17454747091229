// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatInputButton_continueWrapper__FYtuE {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}
.ChatInputButton_continueWrapper__FYtuE .ChatInputButton_continueBtn__aJB2L {
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatUi/ChatInput/InputComponents/ChatInputButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;AACJ","sourcesContent":[".continueWrapper {\n  display: flex;\n  justify-content: center;\n  padding: 0 10px;\n\n  .continueBtn {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"continueWrapper": `ChatInputButton_continueWrapper__FYtuE`,
	"continueBtn": `ChatInputButton_continueBtn__aJB2L`
};
export default ___CSS_LOADER_EXPORT___;
