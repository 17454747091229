// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseCatalogList_courseCatalogList__oitQF {
  height: 100%;
  display: flex;
  padding: 10px 20px;
  flex-flow: column nowrap;
  box-sizing: border-box;
}
.CourseCatalogList_courseCatalogList__oitQF .CourseCatalogList_titleRow__X2ezv {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.CourseCatalogList_courseCatalogList__oitQF .CourseCatalogList_titleRow__X2ezv .CourseCatalogList_titleArea__RDKoz {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.CourseCatalogList_courseCatalogList__oitQF .CourseCatalogList_titleRow__X2ezv .CourseCatalogList_titleArea__RDKoz .CourseCatalogList_icon__U4Grw {
  width: 16px;
}
.CourseCatalogList_courseCatalogList__oitQF .CourseCatalogList_titleRow__X2ezv .CourseCatalogList_titleArea__RDKoz .CourseCatalogList_titleName__y6dtT {
  margin-left: 12px;
  font-weight: var(--font-weight-bold);
  color: var(--font-color-main);
}
.CourseCatalogList_courseCatalogList__oitQF .CourseCatalogList_titleRow__X2ezv .CourseCatalogList_chapterCount__JwNTd {
  font-size: var(--font-size-5);
  color: var(--color-8);
}
.CourseCatalogList_courseCatalogList__oitQF .CourseCatalogList_listRow__WF5-6 {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/CourseCatalog/CourseCatalogList.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,wBAAA;EACA,sBAAA;AACF;AACE;EACE,cAAA;EACA,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AACN;AACM;EACE,WAAA;AACR;AAEM;EACE,iBAAA;EACA,oCAAA;EACA,6BAAA;AAAR;AAII;EACE,6BAAA;EACA,qBAAA;AAFN;AAME;EACE,eAAA;AAJJ","sourcesContent":[".courseCatalogList {\n  height: 100%;\n  display: flex;\n  padding: 10px 20px;\n  flex-flow: column nowrap;\n  box-sizing: border-box;\n\n  .titleRow {\n    flex: 0 0 auto;\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n\n    .titleArea {\n      display: flex;\n      flex-flow: row nowrap;\n      align-items: center;\n\n      .icon {\n        width: 16px;\n      }\n\n      .titleName {\n        margin-left: 12px;\n        font-weight: var(--font-weight-bold);\n        color: var(--font-color-main);\n      }\n    }\n\n    .chapterCount {\n      font-size: var(--font-size-5);\n      color: var(--color-8);\n    }\n  }\n\n  .listRow {\n    margin-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseCatalogList": `CourseCatalogList_courseCatalogList__oitQF`,
	"titleRow": `CourseCatalogList_titleRow__X2ezv`,
	"titleArea": `CourseCatalogList_titleArea__RDKoz`,
	"icon": `CourseCatalogList_icon__U4Grw`,
	"titleName": `CourseCatalogList_titleName__y6dtT`,
	"chapterCount": `CourseCatalogList_chapterCount__JwNTd`,
	"listRow": `CourseCatalogList_listRow__WF5-6`
};
export default ___CSS_LOADER_EXPORT___;
