import i18n from 'i18next';
export const SEX = {
  MALE: 'male',
  FEMALE: 'female',
  SECRET: 'secret',
};

export const SEX_NAMES = {
  [SEX.MALE]: '男性',
  [SEX.FEMALE]: '女性',
  [SEX.SECRET]: '保密'
};
