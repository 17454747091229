// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavHeader_navHeader__nJPm9 {
  height: 60px;
  padding: 0 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  transition: width 3s ease;
}
.NavHeader_navHeader__nJPm9.NavHeader_mobile__vYIRL {
  height: 76px;
}
.NavHeader_navHeader__nJPm9 .NavHeader_logoArea__Mh6HI {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.NavHeader_navHeader__nJPm9 .NavHeader_actionBtn__9V-lH {
  width: 16px;
  text-align: center;
  vertical-align: middle;
}
.NavHeader_navHeader__nJPm9 .NavHeader_actionBtn__9V-lH:hover {
  cursor: pointer;
}
.NavHeader_navHeader__nJPm9 .NavHeader_actionBtn__9V-lH .NavHeader_icon__NXILb {
  width: 16px;
  transition: transform 0.3s ease;
  transform: rotate(-180deg);
}
.NavHeader_navHeader__nJPm9.NavHeader_collapse__o6KpE {
  padding: 22px 0;
  width: 60px;
  flex-flow: column nowrap;
}
.NavHeader_navHeader__nJPm9.NavHeader_collapse__o6KpE .NavHeader_actionBtn__9V-lH {
  margin-bottom: 20px;
}
.NavHeader_navHeader__nJPm9.NavHeader_collapse__o6KpE .NavHeader_actionBtn__9V-lH .NavHeader_icon__NXILb {
  transform: rotate(0deg);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/NavDrawer/NavHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,kBAAA;EACA,sBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAGI;EACE,WAAA;EACA,+BAAA;EACA,0BAAA;AADN;AAKE;EACE,eAAA;EACA,WAAA;EACA,wBAAA;AAHJ;AAKI;EAIE,mBAAA;AANN;AAGM;EACE,uBAAA;AADR","sourcesContent":[".navHeader {\n  height: 60px;\n  padding: 0 20px;\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  justify-content: space-between;\n  transition: width 3s ease;\n\n  &.mobile {\n    height: 76px;\n  }\n\n  .logoArea {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n  }\n\n  .actionBtn {\n    width: 16px;\n    text-align: center;\n    vertical-align: middle;\n\n    &:hover {\n      cursor: pointer;\n    }\n    .icon {\n      width: 16px;\n      transition: transform 0.3s ease;\n      transform: rotate(-180deg);\n    }\n  }\n\n  &.collapse {\n    padding: 22px 0;\n    width: 60px;\n    flex-flow: column nowrap;\n\n    .actionBtn {\n      .icon {\n        transform: rotate(0deg);\n      }\n      margin-bottom: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navHeader": `NavHeader_navHeader__nJPm9`,
	"mobile": `NavHeader_mobile__vYIRL`,
	"logoArea": `NavHeader_logoArea__Mh6HI`,
	"actionBtn": `NavHeader_actionBtn__9V-lH`,
	"icon": `NavHeader_icon__NXILb`,
	"collapse": `NavHeader_collapse__o6KpE`
};
export default ___CSS_LOADER_EXPORT___;
