// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatButtonGroup_buttonGroupWrapper__KJV\\+o {
  padding: 0 10px;
}
.ChatButtonGroup_buttonGroupWrapper__KJV\\+o .ChatButtonGroup_ChatButtonGroup__vtHEM {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatUi/ChatInput/InputComponents/ChatButtonGroup.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACJ","sourcesContent":[".buttonGroupWrapper {\n  padding: 0 10px;\n\n  .ChatButtonGroup {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroupWrapper": `ChatButtonGroup_buttonGroupWrapper__KJV+o`,
	"ChatButtonGroup": `ChatButtonGroup_ChatButtonGroup__vtHEM`
};
export default ___CSS_LOADER_EXPORT___;
