// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatUi_ChatUi__URVD8 {
  display: flex;
  height: 100%;
  background: #F8FBFC;
  flex: 1 1 auto;
  justify-content: center;
  position: relative;
}
.ChatUi_ChatUi__URVD8.ChatUi_mobile__bQKbk {
  width: 100%;
}
.ChatUi_ChatUi__URVD8 .ChatUi_chatComponents__Unl-W {
  width: 100%;
  max-width: 1000px;
}
.ChatUi_ChatUi__URVD8 .ChatUi_UserSettings__77THq {
  position: absolute;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatUi/ChatUi.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;EACA,uBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,WAAA;EACA,iBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AADJ","sourcesContent":[".ChatUi {\n  display: flex;\n  height: 100%;\n  background: #F8FBFC;\n  flex: 1 1 auto;\n  justify-content: center;\n  position: relative;\n\n  &.mobile {\n    width: 100%;\n  }\n\n  .chatComponents {\n    width: 100%;\n    max-width: 1000px;\n  }\n\n  .UserSettings {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatUi": `ChatUi_ChatUi__URVD8`,
	"mobile": `ChatUi_mobile__bQKbk`,
	"chatComponents": `ChatUi_chatComponents__Unl-W`,
	"UserSettings": `ChatUi_UserSettings__77THq`
};
export default ___CSS_LOADER_EXPORT___;
