// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CouponCodeModal_couponCodeModal__C\\+r\\+q .CouponCodeModal_payModalContent__VHIIF {
  display: flex;
}
.CouponCodeModal_couponCodeModal__C\\+r\\+q .CouponCodeModal_payModalContent__VHIIF .CouponCodeModal_paySection__43F3B {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Pay/CouponCodeModal.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEI;EACE,aAAA;EACA,wBAAA;EACA,mBAAA;AAAN","sourcesContent":[".couponCodeModal {\n  .payModalContent {\n    display: flex;\n\n    .paySection {\n      display: flex;\n      flex-flow: column nowrap;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"couponCodeModal": `CouponCodeModal_couponCodeModal__C+r+q`,
	"payModalContent": `CouponCodeModal_payModalContent__VHIIF`,
	"paySection": `CouponCodeModal_paySection__43F3B`
};
export default ___CSS_LOADER_EXPORT___;
