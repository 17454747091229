// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyButton_copyButton__mks8N.CopyButton_copyed__-hwSU {
  color: var(--font-color-main);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatUi/ChatMessage/CopyButton.module.scss"],"names":[],"mappings":"AAEE;EACE,6BAAA;AADJ","sourcesContent":[".copyButton {\n\n  &.copyed {\n    color: var(--font-color-main);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyButton": `CopyButton_copyButton__mks8N`,
	"copyed": `CopyButton_copyed__-hwSU`
};
export default ___CSS_LOADER_EXPORT___;
