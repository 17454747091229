// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainButtonM_mainButtonM__p\\+98d {
  --adm-color-primary: var(--color-1);
}`, "",{"version":3,"sources":["webpack://./src/Components/m/MainButtonM.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF","sourcesContent":[".mainButtonM {\n  --adm-color-primary: var(--color-1);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainButtonM": `MainButtonM_mainButtonM__p+98d`
};
export default ___CSS_LOADER_EXPORT___;
