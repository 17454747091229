// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_IconButton__4OG1z {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.IconButton_IconButton__4OG1z:active {
  background-color: var(--color-9);
}
.IconButton_IconButton__4OG1z:hover {
  background-color: var(--color-4);
}
.IconButton_IconButton__4OG1z.IconButton_selected__\\+PDnB {
  background-color: var(--color-2);
}
.IconButton_IconButton__4OG1z .IconButton_innerIcon__6EjMo {
  display: block;
  width: 46%;
}`, "",{"version":3,"sources":["webpack://./src/Components/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;AACF;AACE;EACE,gCAAA;AACJ;AAEE;EACE,gCAAA;AAAJ;AAGE;EACE,gCAAA;AADJ;AAIE;EACE,cAAA;EACA,UAAA;AAFJ","sourcesContent":[".IconButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: transparent;\n\n  &:active {\n    background-color: var(--color-9);\n  }\n\n  &:hover {\n    background-color: var(--color-4);\n  }\n\n  &.selected {\n    background-color: var(--color-2);\n  }\n\n  .innerIcon {\n    display: block;\n    width: 46%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": `IconButton_IconButton__4OG1z`,
	"selected": `IconButton_selected__+PDnB`,
	"innerIcon": `IconButton_innerIcon__6EjMo`
};
export default ___CSS_LOADER_EXPORT___;
