// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingInputM_settingInputM__S4gAl .SettingInputM_title__HE0R5 {
  font-size: var(--font-size-5);
  color: var(--color-9);
}
.SettingInputM_settingInputM__S4gAl .SettingInputM_inputWrapper__DD-B5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.SettingInputM_settingInputM__S4gAl .SettingInputM_inputWrapper__DD-B5 .SettingInputM_inputElement__REiW8 {
  --font-size: var(--font-size-4);
  padding-bottom: 5px;
  color: var(--font-color-main);
  border-bottom: 1px solid var(--color-9);
}
.SettingInputM_settingInputM__S4gAl .SettingInputM_errorMessage__9rB0A {
  font-size: var(--font-size-5);
  color: var(--fail-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/m/SettingInputM.module.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;EACA,qBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,mBAAA;AADJ;AAGI;EACE,+BAAA;EACA,mBAAA;EACA,6BAAA;EACA,uCAAA;AADN;AAKE;EACE,6BAAA;EACA,wBAAA;AAHJ","sourcesContent":[".settingInputM {\n  .title {\n    font-size: var(--font-size-5);\n    color: var(--color-9);\n  }\n\n  .inputWrapper {\n    padding-top: 5px;\n    padding-bottom: 5px;\n\n    .inputElement {\n      --font-size: var(--font-size-4);\n      padding-bottom: 5px;\n      color: var(--font-color-main);\n      border-bottom: 1px solid var(--color-9);\n    }\n  }\n\n  .errorMessage {\n    font-size: var(--font-size-5);\n    color: var(--fail-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingInputM": `SettingInputM_settingInputM__S4gAl`,
	"title": `SettingInputM_title__HE0R5`,
	"inputWrapper": `SettingInputM_inputWrapper__DD-B5`,
	"inputElement": `SettingInputM_inputElement__REiW8`,
	"errorMessage": `SettingInputM_errorMessage__9rB0A`
};
export default ___CSS_LOADER_EXPORT___;
