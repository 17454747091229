// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeedbackModal_feedbackModal__ohnZP .ant-modal-body {
  padding: 20px 16px 15px 16px;
  color: var(--font-color-main);
}
.FeedbackModal_feedbackModal__ohnZP .ant-modal-body .FeedbackModal_title__vAGT2 {
  text-align: center;
  font-size: var(--font-size-2);
}
.FeedbackModal_feedbackModal__ohnZP .ant-modal-body .FeedbackModal_formWrapper__Q1kxj {
  margin-top: 30px;
}
.FeedbackModal_feedbackModal__ohnZP .ant-modal-body .FeedbackModal_okBtn__z7G5i {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/FeedbackModal/FeedbackModal.module.scss"],"names":[],"mappings":"AACE;EACE,4BAAA;EACA,6BAAA;AAAJ;AAEI;EACE,kBAAA;EACA,6BAAA;AAAN;AAGI;EACE,gBAAA;AADN;AAII;EACE,gBAAA;AAFN","sourcesContent":[".feedbackModal {\n  :global(.ant-modal-body) {\n    padding: 20px 16px 15px 16px;\n    color: var(--font-color-main);\n\n    .title {\n      text-align: center;\n      font-size: var(--font-size-2);\n    }\n\n    .formWrapper {\n      margin-top: 30px;\n    }\n\n    .okBtn {\n      margin-top: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedbackModal": `FeedbackModal_feedbackModal__ohnZP`,
	"title": `FeedbackModal_title__vAGT2`,
	"formWrapper": `FeedbackModal_formWrapper__Q1kxj`,
	"okBtn": `FeedbackModal_okBtn__z7G5i`
};
export default ___CSS_LOADER_EXPORT___;
