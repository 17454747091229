// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBody_navBody__GjsjH {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.NavBody_navBody__GjsjH .NavBody_productName__CYf5G {
  margin-top: 8px;
  font-weight: var(--font-weight-bold);
  color: var(--font-color-title);
}
.NavBody_navBody__GjsjH .NavBody_slogan__Qhh3H {
  margin-top: 8px;
}
.NavBody_navBody__GjsjH .NavBody_btnWrapper__a\\+igl {
  margin-top: 40px;
  width: 185px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/NavDrawer/NavBody.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,oCAAA;EACA,8BAAA;AACJ;AACE;EACE,eAAA;AACJ;AACE;EACE,gBAAA;EACA,YAAA;AACJ","sourcesContent":[".navBody {\n  height: 100%;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n  justify-content: center;\n\n  .productName {\n    margin-top: 8px;\n    font-weight: var(--font-weight-bold);\n    color: var(--font-color-title);\n  }\n  .slogan {\n    margin-top: 8px;\n  }\n  .btnWrapper {\n    margin-top: 40px;\n    width: 185px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBody": `NavBody_navBody__GjsjH`,
	"productName": `NavBody_productName__CYf5G`,
	"slogan": `NavBody_slogan__Qhh3H`,
	"btnWrapper": `NavBody_btnWrapper__a+igl`
};
export default ___CSS_LOADER_EXPORT___;
