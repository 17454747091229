// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingRadioElement_settingRadio__83zIa {
  position: relative;
  padding: 4px 0 10px 0;
  border-bottom: 1px solid var(--color-9);
}
.SettingRadioElement_settingRadio__83zIa .SettingRadioElement_title__O5mLP {
  height: var(--font-size-3);
  line-height: var(--font-size-3);
  font-size: var(--font-size-5);
  color: var(--color-9);
}
.SettingRadioElement_settingRadio__83zIa .SettingRadioElement_inputWrapper__Xxts\\+ {
  margin-top: 10px;
}
.SettingRadioElement_settingRadio__83zIa .SettingRadioElement_inputWrapper__Xxts\\+ .SettingRadioElement_inputElement__X4p10 {
  color: var(--font-color-main);
  accent-color: var(--font-color-main);
  font-size: var(--font-size-4);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/SettingRadioElement.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,uCAAA;AACF;AACE;EACE,0BAAA;EACA,+BAAA;EACA,6BAAA;EACA,qBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,6BAAA;EACA,oCAAA;EACA,6BAAA;AAAN","sourcesContent":[".settingRadio {\n  position: relative;\n  padding: 4px 0 10px 0;\n  border-bottom: 1px solid var(--color-9);\n\n  .title {\n    height: var(--font-size-3);\n    line-height: var(--font-size-3);\n    font-size: var(--font-size-5);\n    color: var(--color-9);\n  }\n\n  .inputWrapper {\n    margin-top: 10px;\n\n    .inputElement {\n      color: var(--font-color-main);\n      accent-color: var(--font-color-main);\n      font-size: var(--font-size-4);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingRadio": `SettingRadioElement_settingRadio__83zIa`,
	"title": `SettingRadioElement_title__O5mLP`,
	"inputWrapper": `SettingRadioElement_inputWrapper__Xxts+`,
	"inputElement": `SettingRadioElement_inputElement__X4p10`
};
export default ___CSS_LOADER_EXPORT___;
