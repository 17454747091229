// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavFooter_navFooter__XNsSx {
  padding: 25px 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--color-5-1);
  font-size: var(--font-size-5);
}
.NavFooter_navFooter__XNsSx.NavFooter_collapse__2mf8o {
  padding-left: 0;
  padding-right: 0;
  width: 56px;
  flex-flow: column nowrap;
}
.NavFooter_navFooter__XNsSx.NavFooter_collapse__2mf8o .NavFooter_settingBtn__CoUkk {
  margin-top: 20px;
  -webkit-user-select: none;
          user-select: none;
}
.NavFooter_navFooter__XNsSx.NavFooter_collapse__2mf8o .NavFooter_settingBtn__CoUkk .NavFooter_btnText__UW60L {
  display: none;
}
.NavFooter_navFooter__XNsSx .NavFooter_settingBtn__CoUkk {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.NavFooter_navFooter__XNsSx .NavFooter_settingBtn__CoUkk:hover {
  cursor: pointer;
}
.NavFooter_navFooter__XNsSx .NavFooter_settingBtn__CoUkk .NavFooter_icon__Gu-XY {
  width: 16px;
}
.NavFooter_navFooter__XNsSx .NavFooter_settingBtn__CoUkk .NavFooter_btnText__UW60L {
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/NavDrawer/NavFooter.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,kCAAA;EACA,6BAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,wBAAA;AACJ;AACI;EACE,gBAAA;EAIA,yBAAA;UAAA,iBAAA;AAFN;AADM;EACE,aAAA;AAGR;AAGE;EACE,aAAA;EACA,wBAAA;EACA,mBAAA;AADJ;AAEI;EACE,eAAA;AAAN;AAGI;EACE,WAAA;AADN;AAII;EACE,eAAA;AAFN","sourcesContent":[".navFooter {\n  padding: 25px 30px;\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  background-color: var(--color-5-1);\n  font-size: var(--font-size-5);\n\n  &.collapse {\n    padding-left: 0;\n    padding-right: 0;\n    width: 56px;\n    flex-flow: column nowrap;\n\n    .settingBtn {\n      margin-top: 20px;\n      .btnText {\n        display: none;\n      }\n      user-select: none;\n    }\n  }\n\n  .settingBtn {\n    display: flex;\n    flex-flow: column nowrap;\n    align-items: center;\n    &:hover {\n      cursor: pointer;\n    }\n\n    .icon {\n      width: 16px;\n    }\n\n    .btnText {\n      margin-top: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navFooter": `NavFooter_navFooter__XNsSx`,
	"collapse": `NavFooter_collapse__2mf8o`,
	"settingBtn": `NavFooter_settingBtn__CoUkk`,
	"btnText": `NavFooter_btnText__UW60L`,
	"icon": `NavFooter_icon__Gu-XY`
};
export default ___CSS_LOADER_EXPORT___;
