// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PayModalFooter_protocalWrapper__seGQA {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-5);
}
.PayModalFooter_protocalWrapper__seGQA .PayModalFooter_protocalDesc__3ah1N {
  color: var(--color-8);
}
.PayModalFooter_protocalWrapper__seGQA .PayModalFooter_protocalLinks__PvC96 {
  margin-top: 6px;
}
.PayModalFooter_protocalWrapper__seGQA .PayModalFooter_protocalLinks__PvC96 .PayModalFooter_protocalLink__wdSpp {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Pay/PayModalFooter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wBAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;AACF;AACE;EACE,qBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAEI;EACE,qBAAA;AAAN","sourcesContent":[".protocalWrapper {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: center;\n  font-size: var(--font-size-5);\n\n  .protocalDesc {\n    color: var(--color-8);\n  }\n\n  .protocalLinks {\n    margin-top: 6px;\n\n    .protocalLink {\n      text-decoration: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"protocalWrapper": `PayModalFooter_protocalWrapper__seGQA`,
	"protocalDesc": `PayModalFooter_protocalDesc__3ah1N`,
	"protocalLinks": `PayModalFooter_protocalLinks__PvC96`,
	"protocalLink": `PayModalFooter_protocalLink__wdSpp`
};
export default ___CSS_LOADER_EXPORT___;
