// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageContainer .MarkdownBubble_inlineCode__C-Aai {
  padding: 2px 4px;
  color: var(--color-2);
  font-size: 1em;
  background-color: var(--color-3);
  border-radius: 4px;
  font-family: var(--main-font-family);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatUi/ChatMessage/MarkdownBubble.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,qBAAA;EACA,cAAA;EACA,gCAAA;EACA,kBAAA;EACA,oCAAA;AAAJ","sourcesContent":[":global(.MessageContainer) {\n  .inlineCode {\n    padding: 2px 4px;\n    color: var(--color-2);\n    font-size: 1em;\n    background-color: var(--color-3);\n    border-radius: 4px;\n    font-family: var(--main-font-family);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineCode": `MarkdownBubble_inlineCode__C-Aai`
};
export default ___CSS_LOADER_EXPORT___;
