// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilingModal_filingModal__j0p7e {
  font-size: var(--font-size-5);
  padding: 10px;
  line-height: 2em;
}
.FilingModal_filingModal__j0p7e .ant-modal-content {
  padding: 0;
}
.FilingModal_filingModal__j0p7e .FilingModal_miitLink__q78mG, .FilingModal_filingModal__j0p7e .FilingModal_miitLink__q78mG:visited {
  text-decoration: none;
  color: var(--font-color-main);
}
.FilingModal_filingModal__j0p7e .FilingModal_gonganRow__av6Ls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.FilingModal_filingModal__j0p7e .FilingModal_gonganRow__av6Ls .FilingModal_beianIcon__oZnnL {
  width: 12px;
  margin-right: 3px;
}
.FilingModal_filingModal__j0p7e .FilingModal_btnGroup__lOw6J {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.FilingModal_filingModal__j0p7e .FilingModal_btnGroup__lOw6J .FilingModal_actionBtn__dBV\\+- {
  padding: 0;
  font-size: var(--font-size-5);
  color: var(--color-2);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/NavDrawer/FilingModal.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,aAAA;EACA,gBAAA;AACF;AAEE;EACE,UAAA;AAAJ;AAGE;EACE,qBAAA;EACA,6BAAA;AADJ;AAIE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAFJ;AAII;EACE,WAAA;EACA,iBAAA;AAFN;AAOE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,8BAAA;AALJ;AAOI;EACE,UAAA;EACA,6BAAA;EACA,qBAAA;AALN","sourcesContent":[".filingModal {\n  font-size: var(--font-size-5);\n  padding: 10px;\n  line-height: 2em;\n\n\n  :global(.ant-modal-content) {\n    padding: 0;\n  }\n\n  .miitLink, .miitLink:visited {\n    text-decoration: none;\n    color: var(--font-color-main);\n  }\n\n  .gonganRow {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n\n    .beianIcon {\n      width: 12px;\n      margin-right: 3px;\n    }\n  }\n\n\n  .btnGroup {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n    justify-content: space-between;\n\n    .actionBtn {\n      padding: 0;\n      font-size: var(--font-size-5);\n      color: var(--color-2);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filingModal": `FilingModal_filingModal__j0p7e`,
	"miitLink": `FilingModal_miitLink__q78mG`,
	"gonganRow": `FilingModal_gonganRow__av6Ls`,
	"beianIcon": `FilingModal_beianIcon__oZnnL`,
	"btnGroup": `FilingModal_btnGroup__lOw6J`,
	"actionBtn": `FilingModal_actionBtn__dBV+-`
};
export default ___CSS_LOADER_EXPORT___;
