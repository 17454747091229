// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.BirthdaySettingModal_scrollWrapper__rG3Ey {
  background-image: linear-gradient(to bottom, #fff);
  /* 应用遮罩图像，这里使用的是我们创建的背景渐变 */
  mask-image: linear-gradient(to bottom, transparent, black, transparent);
  position: relative;
}
.BirthdaySettingModal_scrollWrapper__rG3Ey .BirthdaySettingModal_scrollInner__-PJHy {
  height: 100%;
  overflow: hidden;
  z-index: 999;
  position: relative;
}
.BirthdaySettingModal_scrollWrapper__rG3Ey .BirthdaySettingModal_scrollInner__-PJHy::-webkit-scrollbar {
  display: none;
}
.BirthdaySettingModal_scrollWrapper__rG3Ey .BirthdaySettingModal_scrollGroup__Wp84H {
  display: block;
}
.BirthdaySettingModal_scrollWrapper__rG3Ey .BirthdaySettingModal_selector__uJ2Wb {
  position: absolute;
  top: calc(50% - 15px);
  left: 0;
  width: 100%;
  height: 30px;
  border-top: 1px solid var(--font-color-main);
  border-bottom: 1px solid var(--font-color-main);
  z-index: 900;
}
.BirthdaySettingModal_scrollWrapper__rG3Ey .BirthdaySettingModal_scrollItem__-PLRq {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/BirthdaySettingModal.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kDAAA;EAEA,2BAAA;EAEA,uEAAA;EAGA,kBAAA;AAFF;AAIE;EAKE,YAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AANJ;AADI;EACE,aAAA;AAGN;AAME;EACE,cAAA;AAJJ;AAOE;EACE,kBAAA;EACA,qBAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,4CAAA;EACA,+CAAA;EACA,YAAA;AALJ;AAQE;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AANJ","sourcesContent":[".scrollWrapper {\n  background-image: linear-gradient(to bottom, #fff);\n\n  /* 应用遮罩图像，这里使用的是我们创建的背景渐变 */\n  // -webkit-mask-image: linear-gradient(to bottom, black, transparent);\n  mask-image: linear-gradient(to bottom, transparent, black, transparent);\n  // mask-image: black;\n\n  position: relative;\n\n  .scrollInner {\n    &::-webkit-scrollbar {\n      display: none;\n    }\n\n    height: 100%;\n    overflow: hidden;\n    z-index: 999;\n    position: relative;\n  }\n\n  .scrollGroup {\n    display: block;\n  }\n\n  .selector {\n    position: absolute;\n    top: calc(50% - 15px);\n    left: 0;\n    width: 100%;\n    height: 30px;\n    border-top: 1px solid var(--font-color-main);\n    border-bottom: 1px solid var(--font-color-main);\n    z-index: 900;\n  }\n\n  .scrollItem {\n    height: 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollWrapper": `BirthdaySettingModal_scrollWrapper__rG3Ey`,
	"scrollInner": `BirthdaySettingModal_scrollInner__-PJHy`,
	"scrollGroup": `BirthdaySettingModal_scrollGroup__Wp84H`,
	"selector": `BirthdaySettingModal_selector__uJ2Wb`,
	"scrollItem": `BirthdaySettingModal_scrollItem__-PLRq`
};
export default ___CSS_LOADER_EXPORT___;
