// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopupModal_popupModalWrapper__GSvEn {
  position: absolute;
  left: 50%;
  top: 50%;
}

.PopupModal_popupModal__Ec0vv {
  min-width: 100px;
  padding: 10px;
  border-radius: 20px;
  background: var(--color-5);
  box-shadow: 0px 0px 8px 0px rgba(15, 99, 238, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/Components/PopupModal.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,kDAAA;AACF","sourcesContent":[".popupModalWrapper {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n}\n\n.popupModal {\n  min-width: 100px;\n  padding: 10px;\n  border-radius: 20px;\n  background: var(--color-5);\n  box-shadow: 0px 0px 8px 0px rgba(15, 99, 238, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupModalWrapper": `PopupModal_popupModalWrapper__GSvEn`,
	"popupModal": `PopupModal_popupModal__Ec0vv`
};
export default ___CSS_LOADER_EXPORT___;
