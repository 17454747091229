// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingInputElement_settingInputElement__CPWWg {
  padding: 4px 0 10px 0;
  border-bottom: 1px solid var(--color-9);
}
.SettingInputElement_settingInputElement__CPWWg .SettingInputElement_title__vMTI0 {
  height: var(--font-size-3);
  line-height: var(--font-size-3);
  font-size: var(--font-size-5);
  color: var(--color-9);
}
.SettingInputElement_settingInputElement__CPWWg .SettingInputElement_inputWrapper__niO\\+1 .SettingInputElement_inputElement__9wsfS {
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  margin-top: 4px;
  width: 100%;
  color: var(--font-main-color);
  font-size: var(--font-size-4);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/SettingInputElement.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,uCAAA;AACF;AACE;EACE,0BAAA;EACA,+BAAA;EACA,6BAAA;EACA,qBAAA;AACJ;AAEI;EACI,uBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,WAAA;EAEA,6BAAA;EACA,6BAAA;AADR","sourcesContent":[".settingInputElement {\n  padding: 4px 0 10px 0;\n  border-bottom: 1px solid var(--color-9);\n\n  .title {\n    height: var(--font-size-3);\n    line-height: var(--font-size-3);\n    font-size: var(--font-size-5);\n    color: var(--color-9);\n  }\n  .inputWrapper {\n    .inputElement {\n        background: transparent;\n        padding: 0;\n        margin: 0;\n        outline: 0;\n        border: 0;\n        margin-top: 4px;\n        width: 100%;\n\n        color: var(--font-main-color);\n        font-size: var(--font-size-4);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingInputElement": `SettingInputElement_settingInputElement__CPWWg`,
	"title": `SettingInputElement_title__vMTI0`,
	"inputWrapper": `SettingInputElement_inputWrapper__niO+1`,
	"inputElement": `SettingInputElement_inputElement__9wsfS`
};
export default ___CSS_LOADER_EXPORT___;
