// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  display: block;
  width: 100%;
  height: 100%;
}

.NewChatPage_newChatPage__lkasm {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-4-1);
  color: var(--font-color-main);
}
.NewChatPage_newChatPage__lkasm.NewChatPage_pc__eRYPt {
  display: block;
}
.NewChatPage_newChatPage__lkasm.NewChatPage_pad__4r9bd {
  display: block;
}
.NewChatPage_newChatPage__lkasm.NewChatPage_m__1rCaa {
  display: block;
}
.NewChatPage_newChatPage__lkasm .NewChatPage_chatMobileHeader__t68-M {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/NewChatPage.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,qBAAA;EACA,6BAAA;EACA,mCAAA;EACA,6BAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;AAFJ","sourcesContent":[":global(#root) {\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n\n.newChatPage {\n  height: 100%;\n  display: flex;\n  flex-flow: row nowrap;\n  font-size: var(--font-size-4);\n  font-weight: var(--font-weight-4-1);\n  color: var(--font-color-main);\n\n  &.pc {\n    display: block;\n  }\n\n  &.pad {\n    display: block;\n  }\n\n  &.m {\n    display: block;\n  }\n\n  .chatMobileHeader {\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 990;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newChatPage": `NewChatPage_newChatPage__lkasm`,
	"pc": `NewChatPage_pc__eRYPt`,
	"pad": `NewChatPage_pad__4r9bd`,
	"m": `NewChatPage_m__1rCaa`,
	"chatMobileHeader": `NewChatPage_chatMobileHeader__t68-M`
};
export default ___CSS_LOADER_EXPORT___;
