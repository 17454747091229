// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingModal_settingModal__eHpCy {
  display: block;
  padding: 10px;
  font-size: var(--font-size-5);
}
.SettingModal_settingModal__eHpCy .SettingModal_settingRow__0\\+16r {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}
.SettingModal_settingModal__eHpCy .SettingModal_settingRow__0\\+16r:first-child {
  margin-top: 0;
}
.SettingModal_settingModal__eHpCy .SettingModal_settingRow__0\\+16r .SettingModal_rowIcon__8RVSQ {
  width: 16px;
}
.SettingModal_settingModal__eHpCy .SettingModal_loginRow__2XSKu .SettingModal_loginLeft__HyI0K {
  display: flex;
  align-items: center;
}
.SettingModal_settingModal__eHpCy .SettingModal_loginRow__2XSKu .SettingModal_loginLeft__HyI0K .SettingModal_userName__56vwM {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/NavDrawer/SettingModal.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,6BAAA;AACF;AACE;EACE,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACE,aAAA;AACN;AAEI;EACE,WAAA;AAAN;AAKI;EACE,aAAA;EACA,mBAAA;AAHN;AAKM;EACE,gBAAA;AAHR","sourcesContent":[".settingModal {\n  display: block;\n  padding: 10px;\n  font-size: var(--font-size-5);\n\n  .settingRow {\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 30px;\n    cursor: pointer;\n\n    &:first-child {\n      margin-top: 0;\n    }\n\n    .rowIcon {\n      width: 16px;\n    }\n  }\n\n  .loginRow {\n    .loginLeft {\n      display: flex;\n      align-items: center;\n\n      .userName {\n        margin-left: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingModal": `SettingModal_settingModal__eHpCy`,
	"settingRow": `SettingModal_settingRow__0+16r`,
	"rowIcon": `SettingModal_rowIcon__8RVSQ`,
	"loginRow": `SettingModal_loginRow__2XSKu`,
	"loginLeft": `SettingModal_loginLeft__HyI0K`,
	"userName": `SettingModal_userName__56vwM`
};
export default ___CSS_LOADER_EXPORT___;
