// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-btn.MainButton_mainButton__3q1EY {
  position: static;
}
.ant-btn.MainButton_mainButton__3q1EY:not(:disabled):not(.MainButton_ant-btn-disabled__iGdT3):hover {
  position: static;
}`, "",{"version":3,"sources":["webpack://./src/Components/MainButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[":global(.ant-btn).mainButton {\n  position: static;\n\n  &:not(:disabled):not(.ant-btn-disabled):hover {\n    position: static;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainButton": `MainButton_mainButton__3q1EY`,
	"ant-btn-disabled": `MainButton_ant-btn-disabled__iGdT3`
};
export default ___CSS_LOADER_EXPORT___;
