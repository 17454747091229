// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatInputText_inputTextWrapper__IdQXN {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE {
  width: 96%;
  display: flex;
  flex-flow: row nowrap;
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE .ChatInputText_inputWrapper__S0Jyq {
  flex: 1 1 auto;
  position: relative;
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE .ChatInputText_sendIcon__9Wc5d {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE .ChatInputText_sendIcon__9Wc5d::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE .ChatInputText_subBtn__Gc\\+E\\+ {
  margin-left: 10px;
  width: 100px;
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE .ChatInputText_inputField__nushE {
  display: block;
  font-size: var(--font-size-3);
  padding: 0px 36px 0px 16px;
  height: 40px;
  line-height: 38px;
  color: var(--font-color-main);
  border-radius: 20px;
}
.ChatInputText_inputTextWrapper__IdQXN .ChatInputText_inputForm__EiRpE .ChatInputText_inputField__nushE:focus {
  border: 1px solid var(--color-1);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatUi/ChatInput/InputComponents/ChatInputText.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;EACA,qBAAA;AACJ;AACI;EACE,cAAA;EACA,kBAAA;AACN;AAEI;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAAN;AAEM;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;AAAR;AAII;EACE,iBAAA;EACA,YAAA;AAFN;AAKI;EACE,cAAA;EACA,6BAAA;EACA,0BAAA;EACA,YAAA;EACA,iBAAA;EACA,6BAAA;EACA,mBAAA;AAHN;AAKM;EACE,gCAAA;AAHR","sourcesContent":[".inputTextWrapper {\n  display: flex;\n  justify-content: center;\n  padding: 0 10px;\n\n  .inputForm {\n    width: 96%;\n    display: flex;\n    flex-flow: row nowrap;\n\n    .inputWrapper {\n      flex: 1 1 auto;\n      position: relative;\n    }\n\n    .sendIcon {\n      position: absolute;\n      right: 5px;\n      bottom: 5px;\n      width: 30px;\n      height: 30px;\n      cursor: pointer;\n\n      &::after {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        background-color: rgba(255, 255, 255, 0.2);\n      }\n    }\n\n    .subBtn {\n      margin-left: 10px;\n      width: 100px;\n    }\n\n    .inputField {\n      display: block;\n      font-size: var(--font-size-3);\n      padding: 0px 36px 0px 16px;\n      height: 40px;\n      line-height: 38px;\n      color: var(--font-color-main);\n      border-radius: 20px;\n\n      &:focus {\n        border: 1px solid var(--color-1);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputTextWrapper": `ChatInputText_inputTextWrapper__IdQXN`,
	"inputForm": `ChatInputText_inputForm__EiRpE`,
	"inputWrapper": `ChatInputText_inputWrapper__S0Jyq`,
	"sendIcon": `ChatInputText_sendIcon__9Wc5d`,
	"subBtn": `ChatInputText_subBtn__Gc+E+`,
	"inputField": `ChatInputText_inputField__nushE`
};
export default ___CSS_LOADER_EXPORT___;
