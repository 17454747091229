// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalM_modalM__t7N6f {
  --max-width: 100%;
  --adm-color-weak: var(--color-6);
}
.ModalM_modalM__t7N6f .adm-center-popup-wrap {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/m/ModalM.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gCAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[".modalM {\n  --max-width: 100%;\n  --adm-color-weak: var(--color-6);\n\n  :global(.adm-center-popup-wrap) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalM": `ModalM_modalM__t7N6f`
};
export default ___CSS_LOADER_EXPORT___;
