// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PayTotalDiscount_payTotalDiscount__ff90J {
  padding: 8px 15px 7px 15px;
  font-size: var(--font-size-5);
  background: linear-gradient(103deg, #e8cfa4 0%, #f1b87f 100%);
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.PayTotalDiscount_payTotalDiscount__ff90J .PayTotalDiscount_descIcon__q7\\+NU {
  margin-left: 5px;
  position: relative;
  top: -1px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Pay/PayTotalDiscount.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,6BAAA;EACA,6DAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;EACA,SAAA;AACJ","sourcesContent":[".payTotalDiscount {\n  padding: 8px 15px 7px 15px;\n  font-size: var(--font-size-5);\n  background: linear-gradient(103deg, #e8cfa4 0%, #f1b87f 100%);\n  border-radius: 10px;\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n\n  .descIcon {\n    margin-left: 5px;\n    position: relative;\n    top: -1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payTotalDiscount": `PayTotalDiscount_payTotalDiscount__ff90J`,
	"descIcon": `PayTotalDiscount_descIcon__q7+NU`
};
export default ___CSS_LOADER_EXPORT___;
