// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingSelectElement_settingSelect__OuUl0 {
  position: relative;
  padding: 4px 0 10px 0;
  border-bottom: 1px solid var(--color-9);
}
.SettingSelectElement_settingSelect__OuUl0 .SettingSelectElement_title__gvuQ2 {
  height: var(--font-size-3);
  line-height: var(--font-size-3);
  font-size: var(--font-size-5);
  color: var(--color-9);
}
.SettingSelectElement_settingSelect__OuUl0 .SettingSelectElement_inputWrapper__ubOHv {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.SettingSelectElement_settingSelect__OuUl0 .SettingSelectElement_inputWrapper__ubOHv .SettingSelectElement_inputElement__LhGpp {
  flex: 1 1;
  width: 80%;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  color: var(--font-main-color);
  font-size: var(--font-size-4);
  cursor: pointer;
}
.SettingSelectElement_settingSelect__OuUl0 .SettingSelectElement_inputWrapper__ubOHv .SettingSelectElement_icon__DGKZc {
  flex: 0 0 auto;
  display: block;
  width: 16px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/SettingSelectElement.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,uCAAA;AACF;AACE;EACE,0BAAA;EACA,+BAAA;EACA,6BAAA;EACA,qBAAA;AACJ;AAEE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAAJ;AAEI;EACE,SAAA;EACA,UAAA;EACA,uBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;EAEA,6BAAA;EACA,6BAAA;EACA,eAAA;AADN;AAII;EACE,cAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;AAFN","sourcesContent":[".settingSelect {\n  position: relative;\n  padding: 4px 0 10px 0;\n  border-bottom: 1px solid var(--color-9);\n\n  .title {\n    height: var(--font-size-3);\n    line-height: var(--font-size-3);\n    font-size: var(--font-size-5);\n    color: var(--color-9);\n  }\n\n  .inputWrapper {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 5px;\n\n    .inputElement {\n      flex: 1 1 0;\n      width: 80%;\n      background: transparent;\n      padding: 0;\n      margin: 0;\n      outline: 0;\n      border: 0;\n\n      color: var(--font-main-color);\n      font-size: var(--font-size-4);\n      cursor: pointer;\n    }\n\n    .icon {\n      flex: 0 0 auto;\n      display: block;\n      width: 16px;\n      cursor: pointer;\n    }\n  }\n\n\n  .icon {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingSelect": `SettingSelectElement_settingSelect__OuUl0`,
	"title": `SettingSelectElement_title__gvuQ2`,
	"inputWrapper": `SettingSelectElement_inputWrapper__ubOHv`,
	"inputElement": `SettingSelectElement_inputElement__LhGpp`,
	"icon": `SettingSelectElement_icon__DGKZc`
};
export default ___CSS_LOADER_EXPORT___;
