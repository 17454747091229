// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PayChannelSwitch_channelSwitch__pR1Gz {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 174px;
  border-radius: 40px;
  background-color: rgba(var(--color-1-rgb), 0.05);
  justify-content: space-between;
  padding: 5px;
}
.PayChannelSwitch_channelSwitch__pR1Gz .PayChannelSwitch_channelSwitchItem__gtsc2 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--font-color-main);
  width: 80px;
  border-radius: 30px;
  padding: 5px 0;
}
.PayChannelSwitch_channelSwitch__pR1Gz .PayChannelSwitch_channelSwitchItem__gtsc2.PayChannelSwitch_selected__-Evoi {
  background-color: var(--color-5);
}
.PayChannelSwitch_channelSwitch__pR1Gz .PayChannelSwitch_channelSwitchItem__gtsc2 .PayChannelSwitch_channelIcon__yDdPM {
  width: 16px;
}
.PayChannelSwitch_channelSwitch__pR1Gz .PayChannelSwitch_channelSwitchItem__gtsc2 .PayChannelSwitch_channelText__Tbeif {
  margin-left: 5px;
  line-height: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Pay/PayChannelSwitch.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,gDAAA;EACA,8BAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,6BAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;AACJ;AACI;EACE,gCAAA;AACN;AAEI;EACE,WAAA;AAAN;AAGI;EACE,gBAAA;EACA,iBAAA;EACA,YAAA;AADN","sourcesContent":[".channelSwitch {\n  display: flex;\n  flex-flow: row nowrap;\n  box-sizing: border-box;\n  width: 174px;\n  border-radius: 40px;\n  background-color: rgba(var(--color-1-rgb), 0.05);\n  justify-content: space-between;\n  padding: 5px;\n\n  .channelSwitchItem {\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    color: var(--font-color-main);\n    width: 80px;\n    border-radius: 30px;\n    padding: 5px 0;\n\n    &.selected {\n      background-color: var(--color-5);\n    }\n\n    .channelIcon {\n      width: 16px;\n    }\n\n    .channelText {\n      margin-left: 5px;\n      line-height: 16px;\n      height: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelSwitch": `PayChannelSwitch_channelSwitch__pR1Gz`,
	"channelSwitchItem": `PayChannelSwitch_channelSwitchItem__gtsc2`,
	"selected": `PayChannelSwitch_selected__-Evoi`,
	"channelIcon": `PayChannelSwitch_channelIcon__yDdPM`,
	"channelText": `PayChannelSwitch_channelText__Tbeif`
};
export default ___CSS_LOADER_EXPORT___;
