// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingBaseModal_SettingBaseModal__NRlqZ .ant-modal-body {
  padding: 10px 16px 10px 16px;
  color: var(--font-color-main);
}
.SettingBaseModal_SettingBaseModal__NRlqZ .SettingBaseModal_header__MQuJE {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-2);
  text-align: center;
  padding-bottom: 30px;
}
.SettingBaseModal_SettingBaseModal__NRlqZ .SettingBaseModal_btnWrapper__tdzNu {
  margin-top: 40px;
}
.SettingBaseModal_SettingBaseModal__NRlqZ .SettingBaseModal_modalWrapper__EcnoC {
  box-sizing: border-box;
  padding: 20px 28px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/SettingBaseModal.module.scss"],"names":[],"mappings":"AACE;EACE,4BAAA;EACA,6BAAA;AAAJ;AAGE;EACE,6BAAA;EACA,iCAAA;EACA,kBAAA;EACA,oBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,sBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".SettingBaseModal {\n  :global(.ant-modal-body) {\n    padding: 10px 16px 10px 16px;\n    color: var(--font-color-main);\n  }\n\n  .header {\n    font-size: var(--font-size-2);\n    font-weight: var(--font-weight-2);\n    text-align: center;\n    padding-bottom: 30px;\n  }\n\n  .btnWrapper {\n    margin-top: 40px;\n  }\n\n  .modalWrapper {\n    box-sizing: border-box;\n    padding: 20px 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingBaseModal": `SettingBaseModal_SettingBaseModal__NRlqZ`,
	"header": `SettingBaseModal_header__MQuJE`,
	"btnWrapper": `SettingBaseModal_btnWrapper__tdzNu`,
	"modalWrapper": `SettingBaseModal_modalWrapper__EcnoC`
};
export default ___CSS_LOADER_EXPORT___;
