// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SexSettingModal_sexWrapper__PYSIm {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color-main);
}
.SexSettingModal_sexWrapper__PYSIm .SexSettingModal_sexItem__bT36j {
  width: 64px;
  height: 82px;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.SexSettingModal_sexWrapper__PYSIm .SexSettingModal_sexItem__bT36j.selected {
  background-color: var(--color-2);
  cursor: default;
}
.SexSettingModal_sexWrapper__PYSIm .SexSettingModal_sexItem__bT36j.selected .SexSettingModal_itemTitle__nyWZX {
  color: var(--color-4);
}
.SexSettingModal_sexWrapper__PYSIm .SexSettingModal_sexItem__bT36j .SexSettingModal_itemIcon__fHLLk {
  width: 24px;
  display: block;
}
.SexSettingModal_sexWrapper__PYSIm .SexSettingModal_sexItem__bT36j .SexSettingModal_itemTitle__nyWZX {
  margin-top: 10px;
  font-size: var(--font-size-5);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/Settings/SexSettingModal.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,wBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAII;EACE,gCAAA;EACA,eAAA;AAFN;AAIM;EACE,qBAAA;AAFR;AAMI;EACE,WAAA;EACA,cAAA;AAJN;AAOI;EACE,gBAAA;EACA,6BAAA;AALN","sourcesContent":[".SexSettingModal {\n}\n\n.sexWrapper {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  justify-content: space-between;\n  color: var(--font-color-main);\n\n  .sexItem {\n    width: 64px;\n    height: 82px;\n    border-radius: 10px;\n    display: flex;\n    flex-flow: column nowrap;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n\n    &:global(.selected) {\n      background-color: var(--color-2);\n      cursor: default;\n\n      .itemTitle {\n        color: var(--color-4);\n      }\n    }\n\n    .itemIcon {\n      width: 24px;\n      display: block;\n    }\n\n    .itemTitle {\n      margin-top: 10px;\n      font-size: var(--font-size-5);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sexWrapper": `SexSettingModal_sexWrapper__PYSIm`,
	"sexItem": `SexSettingModal_sexItem__bT36j`,
	"itemTitle": `SexSettingModal_itemTitle__nyWZX`,
	"itemIcon": `SexSettingModal_itemIcon__fHLLk`
};
export default ___CSS_LOADER_EXPORT___;
