// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMobileHeader_ChatMobileHeader__Lbek6 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
  padding: 0 12px;
  height: 76px;
  width: 100%;
  box-sizing: border-box;
}
.ChatMobileHeader_ChatMobileHeader__Lbek6 .ChatMobileHeader_logo__6p4WD {
  height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewChatPage/Components/ChatMobileHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,mBAAA;EACA,oCAAA;EACA,2BAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".ChatMobileHeader {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n  background: rgba(255, 255, 255, 0.8);\n  backdrop-filter: blur(15px);\n  padding: 0 12px;\n  height: 76px;\n  width: 100%;\n  box-sizing: border-box;\n\n  .logo {\n    height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatMobileHeader": `ChatMobileHeader_ChatMobileHeader__Lbek6`,
	"logo": `ChatMobileHeader_logo__6p4WD`
};
export default ___CSS_LOADER_EXPORT___;
